.bodyContainerServices {
  margin-left: 4rem;
}

.BannerContainerServices {
  background-size: cover;
  background-image: url("../../Images/AboutUs_BannerImg.jpeg");
  object-fit: cover;
}

.ServicesParent {
  width: 95%;
  margin: auto;
  margin-top: 1rem;
  /* border: 1px solid green; */
}

.SingleServicesAndTxtImgCont {
  width: 100%;
  display: flex;
  justify-content: space-between;
  gap: 2rem;
  align-items: center;
  margin-top: 2rem;
  /* border: 1px solid blue; */
}
/* .SingleServicesAndTxtImgCont > div {
  border: 1px solid green;
} */

.SingleServicesTextContbox {
  width: 60%;
}
.SingleServices {
  margin-top: 5rem;
  /* border: 1px solid black; */

}
.SingleServicesImgCont {
  /* margin-top: 2rem; */
  width: 40%;
  /* border: 1px solid black; */
}
.SingleServicesImgCont img {
  border-radius: 8px;
}
.SingleServicesTextCont {
  text-align: justify;
  margin-top: 3rem;
  list-style: 29px;
}

.SingleServiceInclude > p {
  margin-top: 2rem;
  font-size: larger;
  font-weight: bold;
}
.SingleServiceInclude ul {
  margin-top: 1rem;
  list-style-position: inside;
  list-style-type: none;
}

.SingleServiceInclude ul > li::before {
  content: "➱";
  padding-right: 8px;
  color: #131049;
}

.intro_txt_cont_service {
  width: 95%;
  margin: auto;
  margin-top: 3rem;
  /* border: 1px solid black; */
}

@media screen and (max-width: 767px) {
  .bodyContainerServices {
    margin-left: 0;
    width: 100%;
  }
  .ServicesParent {
    width: 95%;
  }
  .SingleServicesImgCont {
    width: 100%;
  }
  .SingleServicesAndTxtImgCont {
    flex-direction: column;
    gap: 10px;
  }
  .SingleServicesAndTxtImgCont > div {
    width: 100%;
  }
}
