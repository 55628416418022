.bodyContainerSolutions {
  margin-left: 4rem;
}

.BannerContainerSolutions {
  background-size: cover;
  background-image: url("../../Images/AboutUs_BannerImg.jpeg");
  object-fit: cover;
}

.SolutionLinkContainer {
  margin-top: 8rem;
}
.LinksContainer {
  width: 70%;
  /* border: 1px solid green; */
  display: grid;
  grid-template-columns: repeat(10, 1fr);
  /* justify-content: space-between; */
  /* justify-content: space-around; */
  margin: auto;
  /* margin-top: 3rem; */
  font-size: large;
  font-weight: 500;
  font-family: "poppins";
}
.LinksContainer a {
  cursor: pointer;
}
.LinksContainer a:hover {
  color: orange;
  font-weight: bold;
}
.SolutionsContainer {
  width: 95%;
  margin: auto;
  /* border: 1px solid green; */
  /* margin-top: 10rem; */
}
.single_solutions_container {
  /* width: 95%; */
  margin: auto;
  margin-top: 6rem;
  /* border: 1px solid green; */
}

.Solutions_content_container {
  width: 100%;
  /* display: flex; */
  justify-content: space-between;
  align-items: center;
  /* margin-top: 2rem; */
  /* border: 1px solid green; */
}
.Solutions_content_container .text_container {
  width: 100%;
  font-size: 18px;
  line-height: 1.6;
  font-weight: 400;
  text-align: justify;
  margin-top: 2rem;
  /* border: 1px solid green; */
}
.Solutions_content_container > .image_container {
  width: 100%;
  /* border: 1px solid green; */
  margin: auto;
  margin-top: 1rem;
}
/* .big_image_container img{
  width: 60%;
  margin: auto;
} */

@media screen and (max-width: 767px) {
  .bodyContainerSolutions {
    margin-left: 0;
    width: 100%;
  }

  .LinksContainer {
    width: 95%;
    grid-template-columns: repeat(5, 1fr);
    gap: 1rem;
    margin: auto;
    margin-top: 3rem;
    /* border: 1px solid gray; */
  }
  .SolutionsContainer {
    width: 95%;
    margin-top: 0rem;
    /* border: 1px solid rebeccapurple; */
  }

  .single_solutions_container {
    width: 94%;
    /* margin-top: 3rem; */
  }
  .Solutions_content_container {
    flex-direction: column-reverse;
    gap: 3rem;
  }
  .Solutions_content_container .text_container {
    width: 100%;
  }

  .Solutions_content_container .image_container {
    width: 100%;
    margin-top: 2rem;
  }
}
