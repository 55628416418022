@import "slick-carousel/slick/slick.css";
@import "slick-carousel/slick/slick-theme.css";

.bodyContainerAboutUs {
  margin-left: 4rem;
}
.BannerContainerAboutUs {
  /* background-color: #0c447f; */
  background-size: cover;
  background-image: url("../../Images/AboutUs_BannerImg.jpeg");
  /* height: 70vh; */
}

.slick-track {
  padding: 1rem 0;
  /* border: 1px solid green; */
}
.center .slick-center .project {
  transform: scale(1.3);
  z-index: 10000;
  transition: all 400ms ease-in-out;
  font-size: 14px;
  padding: 20px 5px 5px 20px;
  height: 390px;
}
.AboutUsSectionI {
  margin-top: 4rem;
  /* border: 1px solid red; */
}
.AboutUsSectionI-Cont {
  /* width: 95%; */
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
  margin: auto;
  /* border: 1px solid black; */
}
.AboutUsSectionText {
  width: 60%;
  margin-left: 2rem;
  text-align: justify;
  line-height: 30px;
  /* border: 1px solid black; */
}
.AboutUsSectionBtnCont {
  margin-top: 1rem;
}
.AboutUsSectionBtnCont :nth-child(2) {
  margin-left: 2rem;
}

.AboutUsSectionImgCont {
  width: 40%;
  /* max-width: 100%; */
  /* border: 1px solid black; */
}

.AboutUsSectionII {
  margin-top: 4rem;
  /* border: 1px solid navy; */
}
.AboutUsSectionII-Heading {
  text-align: right;
  margin-right: 7.5rem;
}
.AboutUsSectionII-Cont {
  margin: auto;
  display: flex;
  gap: 20px;
  align-items: center;
  justify-content: space-between;
}
.AboutUsSectionIIText {
  width: 60%;
  margin-right: 3rem;
  text-align: justify;
  line-height: 30px;
  /* border: 1px solid black; */
}
.AboutUsSectionIImgCont {
  width: 40%;
  /* border: 1px solid black; */
}
.AI-in-ClinicalTrailHeading {
  margin-left: 3.5rem;
}
.AI-in-ClinicalTrail-Slider {
  margin-top: 2rem;
}

.AI-in-ClinicalTrailCont {
  margin-top: 1rem;
  /* border: 1px solid black; */
}
.OurTeamContainer {
  display: flex;
  margin-top: 5rem;
  /* border: 1px solid skyblue; */
  align-items: center;
  padding: 10px;
}
.TeamMembers {
  width: 40%;
  /* border: 1px solid navy; */
}
.TeamMembers img {
  border-radius: 10px;
  /* margin-left: .5rem; */
}
.OurTeamText {
  width: 60%;
  text-align: justify;
  /* border: 1px solid greenyellow; */
  padding: 0 30px 30px 30px;
  line-height: 30px;
}
.OurTeamText button {
  display: block;
  margin: auto;
  margin-top: 1rem;
}
.OurTeamHeading {
  text-align: center;
  margin-bottom: 2rem;
}
.EmailAndName {
  /* border: 1px solid black; */
  margin-top: 1rem;
}
.EmailAndNameInptCont {
  display: flex;
  margin-left: 3.5rem;
}
.EmailAndNameInptCont input {
  border: 2px solid var(--blue);
  width: 30%;
  padding: 5px 0 5px 20px;
  border-radius: 20px;
}
.EmailAndNameInptCont :last-child {
  margin-left: 2rem;
}
.EmailAndNameBtnCont {
  margin-left: 3.5rem;
  margin-top: 1rem;
}
@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .center .slick-center .project {
    height: 310px;
    /* margin-bottom: 5px; */
  }
}
@media only screen and (max-width: 767px) {
  .bodyContainerAboutUs {
    margin-left: 0;
    width: 100%;
  }
  .AboutUsSectionI-Cont {
    margin-top: 2rem;
    flex-direction: column;
    gap: 2rem;
  }
  .AboutUsSectionII-Cont {
    margin-top: 2rem;
    flex-direction: column-reverse;
  }

  .AboutUsSectionText{
    width: 90%;
    margin-left: 0;
    margin: auto;
  }
  .AboutUsSectionImgCont{
    width: 90%;
  }
  .AboutUsSectionIIText{
    width: 90%;
    margin-right: 0;
    
  }
  .AboutUsSectionIImgCont {
    width: 90%;
    /* border: 1px solid black; */
  }
  .OurTeamContainer {
    width: 100%;
    flex-direction: column-reverse;
    gap: 20px;
    margin-bottom: 2rem;
  }
  .TeamMembers {
    width: 100%;
    /* border: 1px solid navy; */
  }
  .OurTeamText {
    width: 100%;
    padding: 0;
    /* border: 1px solid greenyellow; */
  }
  .OurTeamText {
    margin-top: 1rem;
  }
  .EmailAndNameInptCont {
    flex-direction: column;
    gap: 10px;
  }
  .EmailAndNameInptCont input {
    width: 80%;
  }
  .EmailAndNameInptCont :last-child {
    margin-left: 0;
  }
  .center .slick-center .project {
    height: 300px;
    /* margin-bottom: 5px; */
  }
}
