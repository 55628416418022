.bodyContainerHS {
  margin-left: 4rem;
}

.BannerContainerHS {
  background-size: cover;
  /* background-repeat: no-repeat; */
  background-image: url("../../../Images/Banner-AI-in-CT.jpeg");
  /* height: 90vh; */
}
.VisualHandCont {
  margin-top: 4rem;
  /* border: 1px solid green; */
}
.VisualHandHeading {
  margin-left: 3rem;
}
.VisualHandSubTxt {
  width: 90%;
  /* margin: auto; */
  margin-left: 3rem;
  margin-top: 2rem;
  /* border: 1px solid red; */
  text-align: left;
  line-height: 25px;
}
.VisualHandSubTxt > p:nth-child(2) {
  margin-top: 2rem;
}
.VisualHandSubTxt > p:nth-child(3) {
  margin-top: 2rem;
}

.smallBannerHS {
  margin-top: 4rem;
  width: 100%;
  height: 50vh;
  /* background-color: #0c447f; */
  background-image: url("../../../Images/SecondBanner-AI-In-CT.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  /* border: 1px solid teal; */
}

.smallBannerHSBottom {
  margin-top: 4rem;
  height: 45vh;
  /* border: 1px solid teal; */
  background-image: url("../../../Images/CSfooterRedirect.png");
  background-size: cover;
  background-repeat: no-repeat;
  display: flex;
  color: white;
  align-items: center;
  justify-content: space-between;
}

.smallBannerHSBtmContent {
  display: flex;
  align-items: center;
  font-size: 25px;
  margin: 0px 40px;
  /* border: 1px solid white; */
}

.SmallBannerHSCenterLine {
  height: 10vh;
  border: 1px solid white;
  border-radius: 10px;
  text-align: center;
  line-height: 25px;
}

.BenefitsHS {
  margin-top: 4rem;
}

.BenefitsHSTextCont {
  margin-top: 3rem;
  /* border: 1px solid greenyellow; */
  width: 90%;
  margin: auto;
}
.BenefitsHSTextTitle1 > p:nth-child(1) {
  color: var(--blue);
  font-size: 18px;
  font-weight: bold;
  margin-top: 2rem;
}
.BenefitsHSTextTitle1 > p:nth-child(2) {
  margin-top: 10px;
  margin-left: 1rem;
}
.overallBtmContHS {
  width: 90%;
  margin: auto;
  margin-top: 2rem;
  /* border: 1px solid black; */
}

.BenefitsHSHeading{
  margin-left: 3rem;
}

@media only screen and (max-width: 900px) {
  .smallBannerHS {
    height: 30vh;
    /* background-size: contain; */
  }
}

@media only screen and (max-width: 767px) {
  .bodyContainerHS {
    margin-left: 0;
    width: 100%;
  }

  .smallBannerHS {
    height: 20vh;

    /* background-size: contain; */
  }
  .smallBannerHSBottom {
    height: 30vh;
    flex-direction: column;
    background-size: 100% 100%;
  }
  .smallBannerHSBtmContent{
    /* margin-top: 1rem; */
    margin: auto;
  }
  .SmallBannerHSCenterLine {
    width: 50%;
    height: 2px;
  }
  .VisualHandHeading{
    margin-left: 0.4rem;
  }
  .VisualHandSubTxt{
    width: 95%;
    margin: auto;
    margin-top: 1rem;
  }
  .BenefitsHSHeading{
    margin-left: .4rem;
  }
  .BenefitsHSTextCont{
    width: 95%;
  }
  .overallBtmContHS{
    width: 95%;
  }
}
