.bodyContainerProducts {
  margin-left: 4rem;
}

.BannerContainerProducts {
  background-size: cover;
  background-image: url("../../Images/AboutUs_BannerImg.jpeg");
  object-fit: cover;
}

.below_herobannercont {
  /* border: 1px solid green; */
  margin-top: 2rem;
}
.title_container {
  margin-left: 3rem;
  font-size: 40px;
  font-weight: bold;
  font-family: "poppins";
  /* border: 1px solid green; */
}
.subtext {
  width: 90%;
  margin: auto;
  margin-top: 2rem;
  line-height: 30px;
  text-align: justify;
  /* border: 1px solid green; */
}
.product_highlights {
  margin-top: 3rem;
  color: white;
  padding-bottom: 3rem;
  background-color: #000b2b;
}
.prod_highlights_heading {
  color: white;
  margin-left: 3rem;
  font-size: 30px;
  padding-top: 2rem;
}
.prod_highlights_subtext {
  width: 90%;
  margin: auto;
  margin-top: 2rem;
  line-height: 30px;
}
.prod_highlights_box {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 30px 30px;
  width: 90%;
  margin: auto;
  margin-top: 3rem;
  text-align: justify;
  /* border: 1px solid white; */
}

.product_features_heading {
  margin-left: 3rem;
  font-size: 30px;
  padding-top: 2rem;
}

.product_features_subtext {
  width: 90%;
  margin: auto;
  margin-top: 3rem;
  text-align: justify;
  /* border: 1px solid gray; */
}

.product_features_img_cont {
  width: 90%;
  margin: auto;
  margin-top: 3rem;
  /* border: 1px solid green; */
  display: grid;

  grid-template-columns: repeat(4, 1fr);
  gap: 50px;
}

.img_div img {
  width: 100%;
  /* margin: auto; */
  /* border: 1px solid blue; */
}

.connect_with_us {
  color: white;
  background-color: #000b2b;
  margin-top: 3rem;
}
.connect_with_us_heading_cont {
  /* width: 25%; */
  display: flex;
  /* justify-content: space-between; */
  align-items: center;
  padding-top: 4rem;
  margin-left: 4rem;
}

.link_icon_cont {
  margin-left: 1rem;
}

.connect_with_us_heading {
  color: white;
  font-size: 36px;
}

.connect_us_inpt_cont {
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin: 4rem 0 0 4rem;
  padding-bottom: 8rem;
}

.contact_us_with_message_icon {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: xx-large;
  border-bottom: 2px solid orange;
}

.Products_container_parent {
  margin-top: 8rem;
  /* border: 1px solid green; */
}

.Products_title {
  text-align: center;
  font-size: 28px;
}
.Products_container {
  /* margin-left: 3rem; */
  width: 95%;
  margin: auto;
  margin-top: 3rem;
  /* border: 1px solid green; */
}
.products_text_and_img_cont {
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* border: 1px solid green; */
}

.products_img_cont {
  width: 500px;
  /* border: 1px solid green; */
}
.under_text {
  /* border: 1px solid green; */
  /* list-style-position:; */
  list-style-type: circle;
  line-height: 1.7;
  width: 50%;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  border-radius: 20px;
  padding: 20px 10px 20px 30px;
  text-transform: capitalize;
  /* text-align: justify; */
}

.under_text li::marker {
  color: orange;
}
/* .under_text li {
  border: 1px solid green;
} */

.without_image{
  margin-top: 1rem;
}

@media screen and (max-width: 767px) {
  .bodyContainerProducts {
    margin-left: 0;
    width: 100%;
  }
  .prod_highlights_box {
    grid-template-columns: repeat(1, 1fr);
  }
  .title_container {
    margin-left: 0.5rem;
    font-size: 24px;
  }
  .subtext {
    width: 95%;
    font-size: medium;
    /* margin: auto;
    margin-top: 2rem;
    line-height: 30px; */
    /* border: 1px solid green; */
  }
  .prod_highlights_heading {
    margin-left: 0.5rem;
  }
  .prod_highlights_subtext {
    width: 95%;
  }
  .product_features_heading {
    margin-left: 0.5rem;
  }
  .connect_with_us_heading_cont {
    margin-left: 0.5rem;
  }
  .connect_us_inpt_cont {
    flex-direction: column;
    gap: 2rem;
    margin: 3rem 0 0 0.5rem;
    padding-bottom: 6rem;
  }
  .contact_us_with_message_icon {
    font-size: large;
  }

  .product_features_img_cont {
    width: 95%;
    grid-template-columns: repeat(2, 1fr);
  }

  .Products_container_parent {
    width: 98%;
    margin: auto;
    margin-top: 8rem;
  }
  .Products_container {
    margin: auto;
    margin-top: 3rem;
  }
  .products_text_and_img_cont {
    margin-top: 2rem;
    flex-direction: column-reverse;
    gap: 2rem;
  }
  .products_img_cont {
    width: 100%;
  }
  .under_text {
    width: 100%;
    margin-left: 0.1rem;
    font-size: medium;
  }
}
