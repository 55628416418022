.bodyContainerCarrer {
  margin-left: 4rem;
}

.BannerContainerCarrer {
  background-size: cover;
  background-image: url("../../Images/AboutUs_BannerImg.jpeg");
  height: 60vh;
}
.OurMoto {
  margin-top: 3rem;
  /* border: 1px solid black; */
}

.OurMoto-Cont {
  width: 95%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: auto;
  /* border: 1px solid black; */
  margin-top: 0.5rem;
}
.OurMotoIntroImg {
  width: 40%;
  /* border: 1px solid gray; */
}
.OurMotoText {
  text-align: justify;
  /* padding: 10px; */
  width: 70%;
  /* border: 1px solid black; */
}
.OurMotoBtnCont {
  margin-top: 1rem;
}
.LifeAtRS {
  margin-top: 3rem;
  /* border: 1px solid black; */
}
.LifeAtRS-Sub {
  margin-top: 1rem;
  margin-left: 3.1rem;
}

.CardsParent {
  width: 95%;
  margin: auto;
  margin-top: 4rem;
  /* border: 1px solid black; */
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 40px;
}
.CardsParent div {
  /* border: 1px solid green; */
  border-radius: 20px;
  background-color: #e6e6e6;
}
.CardsParent div h2 {
  margin-top: 1rem;
  text-align: center;
  font-size: larger;
  font-weight: 600;
}
.CardsParent div p {
  /* font-size: 18px; */
  text-align: justify;
  margin-top: 2rem;
  line-height: normal;
  padding: 0 20px 20px 20px;
}

.career_form {
  width: 90%;
  margin: auto;
}

@media screen and (max-width: 767px) {
  .bodyContainerCarrer {
    margin-left: 0;
    width: 100%;
  }
  .OurMoto-Cont {
    flex-direction: column;
    gap: 2rem;
  }
  .OurMotoText {
    width: 95%;
  }
  .OurMotoIntroImg {
    width: 100%;
  }
  .CardsParent {
    grid-template-columns: repeat(1, 1fr);
  }
  .TopInputCont {
    display: flex;
    flex-direction: column;
  }
}
