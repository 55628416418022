.bodyContainerContactUs {
  margin-left: 4rem;
}

.BannerContainerContactUs {
  /* background-color: #0c447f; */
  background-image: url("https://cdn.pixabay.com/photo/2016/08/03/09/04/universe-1566161_1280.jpg");
  height: 60vh;
  background-size: cover;
  /* background-repeat: no-repeat; */
  /* border: 1px solid red; */
}

.ContactHeading {
  margin-left: 3rem;
  text-align: left;
  margin-top: 1rem;
}
.SubContent {
  width: 90%;
  /* border: 1px solid green; */
  margin: auto;
  margin-top: 2rem;
}
.ContactAddress {
  width: 100%;
  display: flex;
  /* border: 1px solid rebeccapurple; */
  /* justify-content: space-between; */
  /* margin: auto; */
  margin-top: 1rem;
}
.Contact {
  width: 100%;
  align-items: center;
  /* display: flex; */
  /* justify-content: space-around; */
  border: 1px solid goldenrod;
}
/* .Contact div {
  border: 1px solid blue;
} */
.Contact div p {
  margin-top: 1rem;
}

.ContactImg {
  width: 60%;
  /* border: 1px solid goldenrod; */
  /* background-color: black; */
  margin-left: 10px;
}

.Location {
  margin-top: 1rem;
  /* display: inline-block; */
  /* position: relative; */
  /* border: 1px solid greenyellow; */
}

.MapImgContainer::before {
  border: 1px solid black;
  /* border-width: 30px 30px 0px 30px; */
  display: inline-block;
  /* padding: 3px; */
  content: "";
  position: absolute;
  top: 0;
  left: 20%;
  z-index: -1; /* Puts the shape behind the image */
  width: 25%;
  /* height: fit-content; */
  margin-top: 20px;
  padding: 200px 200px;
  /* transform: rotate(-45deg); */
  /* -webkit-transform: rotate(-45deg); */
  background-color: var(--blue);
  border-radius: 0 540px 540px 10px;
  /* border: 3px solid green; */
}

.ImgCont {
  width: 30%;
  margin: auto;
  /* border: 1px solid rebeccapurple; */
}

/* ContactUsForm */
.ContactUsForm {
  /* border: 1px solid green; */
  margin-top: 6rem;
}
.form {
  /* width: 90%; */
  border: 2px solid gray;
  margin: auto;
  border-radius: 30px;
}

.formHeading {
  text-align: center;
  color: white;
  background: var(--blue);
  border-radius: 30px 30px 0px 0px;
  padding: 10px;
  /* margin-top: 10px; */
}

.TopInputCont {
  display: flex;
  /* justify-content: space-between; */
  padding: 10px 20px 10px 20px;
}
.TopInputCont input {
  width: 100%;
  padding: 8px 0 8px 20px;
  border: 1px solid black;
  margin-left: 10px;
  margin-right: 10px;
}
.leftInput {
  border-radius: 20px 0 0 20px;
}
.rightInput {
  border-radius: 0px 20px 20px 0px;
}
.FullInput {
  border-radius: 20px;
}
.TopInputCont textarea {
  width: 100%;
  padding: 8px 0 8px 20px;
  border: 1px solid black;
  margin-left: 10px;
  margin-right: 10px;
}
.TopInputCont .FullInputBottom {
  border-radius: 0px 0px 30px 30px;
  padding-bottom: 50px;
}

.SubmitBtn button {
  display: block;
  background-color: var(--blue);
  color: white;
  margin: auto;
  /* width: 80%; */
  padding: 5px 150px 5px 150px;
  border-radius: 0 0px 30px 30px;
}
/* new */
.parent_continer_contact_address {
  margin-top: 2rem;
  display: flex;
  align-items: center;
  border: 1px solid green;
}
.parent_continer_contact_address > div {
  width: 50%;
  border: 1px solid blue;
}
.address_cont {
  text-align: center;
  border: 1px solid yellowgreen;
}
.address_cont > h1 {
  font-size: 24px;
  margin-top: 2rem;
  font-weight: bold;
}
.address_details_cont {
  width: 90%;
  margin: auto;
  margin-top: 1rem;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 12px 28px 0px,
    rgba(0, 0, 0, 0.1) 0px 2px 4px 0px,
    rgba(255, 255, 255, 0.05) 0px 0px 0px 1px inset;
  border: 1px solid blue;
  border-radius: 20px;
}
.address_details_icon_txt_cont {
  margin-top: 2rem;
  display: flex;
  align-items: center;
}
.address_details_icon_txt_cont > p {
  margin-left: 10px;
  font-size: medium;
}
.form_cont {
  padding: 10px;
}

/* New */

.contactUsContainer {
  width: 90%;
  margin: 50px auto;
  /* border: 1px solid green; */
  /*  */
}
.contactBox {
  background: #fff;
  display: flex;
  margin-top: 2rem;
  /* border: 1px solid green; */
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  border-radius: 10px;
}
.contactLeft {
  flex-basis: 60%;
  padding: 40px 60px;
  /* border: 1px solid yellow; */

}
.contactRight {
  flex-basis: 40%;
  padding: 40px 60px;
  /* background: #1b1446; */
  /* color: #fff; */
  /* border: 1px solid yellow; */
}

.contactUsContainer h1 {
  margin-bottom: 10px;
}
.contactUsContainer p {
  margin-bottom: 10px;
}
.contactRight_txt_color{
  color: #1b1446;
  font-weight: bold;
}
.inputRow {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}
.inputRow .inputGroup {
  flex-basis: 45%;
}

.contactUsContainer input {
  width: 100%;
  border: none;
  border-bottom: 1px solid #ccc;
  outline: none;
  padding-bottom: 5px;
  padding-left: 5px;
}
.contactUsContainer textarea {
  width: 100%;
  border: 1px solid #ccc;
  outline: none;
  padding: 10px;
  box-sizing: border-box;
}

.contactUsContainer label {
  margin-bottom: 6px;
  display: block;
  color: #1b1446;
}

.contactUsContainer button {
  background: #1b1446;
  width: 100px;
  border: none;
  outline: none;
  color: #fff;
  height: 35px;
  border-radius: 30px;
  margin-top: 20px;
  box-shadow: 0px 5px 15px 0px rgba(28, 0, 181, 0.3);
}

.contactLeft h1 {
  color: #1b1446;
  font-weight: 600;
  margin-bottom: 30px;
  font-family: "Poppins", sans-serif;
  font-size: larger;
}

.contactRight h1 {
  font-weight: 600;
  margin-bottom: 30px;
  font-family: "Poppins", sans-serif;
  font-size: larger;
  /* border: 1px solid green; */
}

.contactUsContainer tr td:first-child {
  padding-right: 20px;
}

.contactUsContainer tr td {
  padding-top: 20px;
}

.mapCont {
  margin-top: 2rem;
}

@media only screen and (max-width: 1050px) {
  .contactBox {
    flex-direction: column;
    gap: 10px;
  }
}
@media only screen and (max-width: 767px) {
  .bodyContainerContactUs {
    margin-left: 0;
  }
  .ContactAddress {
    flex-direction: column;
    align-items: center;
    gap: 1rem;
  }
  .Contact {
    gap: 20px;
    flex-direction: column;
    align-items: start;
    width: max-content;
  }
  .ContactHeading {
    margin-left: 0.5rem;
  }
  .SubContent {
    width: 95%;
    font-size: medium;
  }
  .ImgCont {
    width: 60%;
  }
  .TopInputCont {
    flex-direction: column;
    gap: 1rem;
  }
  .TopInputCont input {
    border-radius: 20px;
    margin-left: 0;
  }
  .TopInputCont textarea {
    margin-left: 0;
  }
  .SubmitBtn button {
    padding: 5px 60px 5px 60px;
  }

  /*  */
  .contactUsContainer {
    width: 95%;
  }
  .contactBox {
    flex-direction: column;
  }
  .inputRow {
    flex-direction: column;
    gap: 20px;
  }

  .contactLeft {
    padding: 20px 30px;
  }
  .contactRight {
    padding: 20px;
  }
}
