@import "slick-carousel/slick/slick.css";
@import "slick-carousel/slick/slick-theme.css";

.body_container {
  margin-left: 4rem;
}

.slick-track {
  padding: 1rem 0;
  /* border: 1px solid green; */
}
.center .slick-center .project {
  transform: scale(1.3);
  z-index: 10000;
  transition: all 400ms ease-in-out;
  font-size: 14px;
  padding: 20px 5px 55px 20px;
  height: 350px;
  /* border: 1px solid black; */
}

.slider {
  padding-top: 10px;
  margin: auto;
  /* border: 1px solid green; */
  padding-bottom: 30px;
}

.CS {
  display: flex;
  text-align: center;
  margin: auto;
}

.header {
  margin: 50px auto;
  text-align: center;
  font-size: 80px;
}
.BannerContainer {
  background-color: #0c447f;
  /* background-image: url("../../Images/venBannerHomePage.jpeg");
  background-size: cover;
  background-repeat: no-repeat; */
  /* background-position: 0px 30px; */
  /* height: 90vh; */
  /* border: 2px solid black; */
}
.Services > div img:hover {
  transform: scale(1.1);
  z-index: 100;
  transition: all 400ms ease-in-out;
}
.Services > div > p:hover {
  color: #e0a72f;
}

.SolutionsLeft {
  width: 100%;
  display: flex;
  /* padding:0 12rem 0 12rem; */
  list-style-position: inside;
  line-height: 40px;
  /* border: 1px solid blue; */
}

.SolutionsLeft > ul {
  /* width: 10rem; */
  margin: auto;
  /* border: 1px solid goldenrod; */
}
.SolutionsLeft > ul h1 {
  font-size: large;
  font-weight: bold;
}
.SolutionsLeft > ul > li {
  font-size: medium;
  /* border: 1px solid goldenrod; */
}

.Solution_btns {
  margin-top: 2rem;
  align-items: center;
  text-align: center;
}

.Solution_btns > button {
  margin-right: 1rem;
}

.careers {
  position: relative;
  margin-top: 2rem;
  width: 100%;
  height: 100%;
  /* min-height: 320px; */
  min-height: 420px;
  /* border: 1px solid red; */
}

.careers_img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: url("../../Images/Careers.png");
  background-repeat: no-repeat;
  background-size: 100% auto;
  /* border: 1px solid green; */
}

.careers_heading {
  position: absolute;
  top: 20%;
  left: 45%;
  /* border: 1px solid red; */
}
/* .CareerBtnCont {
  border: 1px solid black;
} */
.career_btn_cont button {
  display: block;
  margin: auto;
  text-align: center;
}

.beInTouch {
  margin-top: 2rem;
  position: relative;
  height: 100%;
  width: 100%;
  min-height: 420px;
  /* border: 1px solid red; */
}

.beInTouchImg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 90%;
  /* background: url("../../Images/Contact.png"); */
  background-repeat: no-repeat;
  background-size: cover;
  margin-right: 0;
}

/* .beInTouchInput_Btn {
  display: flex;
  width: 30%;
  border: 1px solid;
  justify-content: space-between;
  margin: auto;
  margin-top: 0.6rem;
} */

.beInTouchInput_Btn {
  width: max-content;
  margin: auto;
  margin-top: 1rem;
  /* border: 1px solid navy; */
}
.beInTouch_subText {
  margin: auto;
  margin-top: 4rem;
  text-align: center;
  /* border: 1px solid; */
  width: 60%;
  /* font-size: 1px; */
  /* font-weight: 500; */
}

.beInTouchInput_Btn > input {
  width: 70%;
  margin-right: 10px;
  border: 1px solid;
}
.beInTouch_heading {
  margin-left: 3rem;
}

.OurClients {
  width: 50%;
  margin: auto;
  /* border: 1px solid slateblue; */
}
.BannerIntroContent {
  margin-left: 1.5rem;
  margin-top: 2rem;
  width: 50%;
  /* border: 1px solid white; */
}

.Process {
  margin-top: 5rem;
  /* border: 1px solid red; */
}
.ProcessHeading {
  margin-left: 3rem;
}
.ProcessImg {
  width: 90%;
  /* border: 1px solid black; */
  margin: auto;
  margin-top: 2rem;
}
.smallScreenNav {
  display: none;
}

.threedots {
  font-size: 1.2rem;
  font-weight: bold;
}
/* xs=576px, sm=768px, md=992px, lg=1200px and xl=>1200px */

@media only screen and (max-width: 767px) {
  .Main {
    width: 100%;
    /* border: 4px solid green; */
  }
  .body_container {
    margin: 0;
    margin-right: 0;
    width: 100%;
  }
  .smallScreenNav {
    display: block;
    /* border: 1px solid red; */
    height: 10vh;
  }
  .bigscreenNav {
    display: none;
  }
  .SolutionsLeft {
    width: 100%;
    /* flex-direction: column; */
    gap: 1rem;
    line-height: 30px;
  }
  .SolutionsLeft > ul {
    /* width: fit-content; */
    margin: auto;
  }
  .Solution_btns {
    width: 220px;
    display: flex;
    margin: auto;
    margin-top: 1rem;
    /* border: 1px solid green; */
  }
  .careers_heading {
    font-size: 10px;
    top: 5%;
    left: 35%;
  }
  .beInTouch_heading {
    font-size: 10px;
    margin-left: 0.5rem;
  }
  .beInTouch_subText {
    margin: auto;
    margin-top: 1rem;
    /* text-align: center; */
    /* border: 1px solid; */
    width: 95%;
    font-size: 10px;
    /* font-weight: 500; */
  }
  .ProcessHeading {
    margin-left: 1rem;
  }
  .careers {
    min-height: 250px;
  }
  .beInTouch {
    min-height: 250px;
  }
  /* .beInTouchInput_Btn {
    width: 30%;
  } */
  /* .beInTouchInput_Btn > input {
    margin-right: 10px;
    width: 80%;
    margin-bottom: 10px;
  } */
  .OurClients {
    width: 85%;
  }
  .BannerIntroContent {
    width: 75%;
  }

  .center .slick-center .project {
    height: 300px;
    /* margin-bottom: 5px; */
  }
}
@media screen and (max-width: 600px) {
  .bodyContainer {
    /* margin-left: 0; */
    width: 100%;
    /* border: 2px solid red; */
  }
  /* .beInTouchInput_Btn {
    width: 30%;
    flex-direction: column;
  } */
  .BannerIntroContent {
    width: 85%;
  }
  /* .beInTouchInput_Btn > input {
    margin-right: 10px;
    width: 100%;
    margin-bottom: 10px;
  } */
}

/*  */

/* @import url('https://fonts.googleapis.com/css?family=Varela+Round'); */
/* html, body {
    overflow-x: hidden;
    height: 100%;
} */
/* body {
    background: #fff;
    padding: 0;
    margin: 0;
    font-family: 'Varela Round', sans-serif;
} */
