.MenuContainer {
  width: 65px;
  height: 100vh;
  position: fixed;
  border-right: 1px solid silver;
  background-color: white;
  z-index: 200;
}
.MenuContainer:hover {
  width: 70px;
  transition-duration: 0.3s;
  transition: all 500ms ease-out;
}
.Menubox {
  display: flex;
  flex-direction: column;
  position: absolute;
  align-items: center;
  width: 100%;
  height: 100%;
  transition: 0.3s ease-in-out;
  cursor: pointer;
  margin-top: 15px;
}

.menu-button-icon {
  width: 25px;
  height: 32px;
  padding: 5.5px 0;
  position: relative;
}
.menu-button-icon span {
  background-color: rgba(32, 32, 32, 0.846);
  display: block;
  width: 35px;
  height: 3px;
  transition: all 0.3s ease-in-out;
  border-radius: 4px;
  /* margin: auto; */
  margin-top: 6px;
}
.menu-button-icon span:first-child {
  width: 30px;
  margin-left: 10px;
}
.menu-button-icon span:last-child {
  width: 30px;
}
.Menubox:hover {
  .menu-button-icon span {
    width: 45px;
  }
  .menu-button-icon span:first-child {
    margin-left: 0;
  }
}

.MenuBarLogo {
  width: 25%;
}

.MenuParentContainer {
  display: flex;
  /* border: 1px solid forestgreen; */
}

.MenuLinkContainer {
  width: 200px;
  /* border: 1px solid wheat; */
}
.MenuLinkContainer div {
  font-size: 20px;
}
.MenuLinkContainer div > a {
  display: inline-block;
  color: white;
  text-decoration: none;
}
.MenuLinkContainer div > a::after {
  content: "";
  display: block;
  width: 0;
  border-bottom: 1px solid #e0a72f;
  transition: width 0.3s;
  margin-top: 5px;
}
.MenuLinkContainer div > a:hover::after {
  width: 100%;
}

/* .nav_child {
  top: 100%;
  border-radius: 8px;
  width: 20vw;
  padding: 20px 20px 0px 20px;
  background-color: white;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  list-style: none;
  text-align: left;
  display: none;
  position: absolute;
  z-index: 5;
  transition: all 0.3s ease-in-out;
} */

.leftLinks {
  position: relative;
  display: inline-block;
  /* padding-right: 20px; */
  /* border: 1px solid yellowgreen; */
}

.leftLinks:hover .SubCategory {
  display: block;
}
.SubCategory {
  position: fixed;
  width: 50vw;
  color: white;
  display: none;
  /* position: absolute; */
  /* position: relative; */
  left: 140px;
  top: 150px;
  padding-left: 40px;
  /* border: 1px solid red; */
  height: 70vh;
}
.LinksRightContainer {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  /* display: flex;
  justify-content: space-between; */
  gap: 20px;
  /* border: 1px solid rebeccapurple; */
  width: 100%;
}
/* .LinksRightContainer > div{
  border: 1px solid floralwhite;
} */
.LinksRightContainer > div ul {
  list-style-position: inside;
  list-style-type: none;
  font-size: 15px;
  line-height: 25px;
  /* border: 1px solid rebeccapurple; */
  margin-top: 10px;
}

.productsLinksCorelabInBox {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 30px;
  /* border: 1px solid black; */
}
.LinksRightContainer > div ul li:hover {
  color: #e0a72f;
  cursor: pointer;
}
.LinksRightContainer > div p {
  color: #e0a72f;
}
.LinksRightContainer > div p:hover {
  color: white;
}
.LinksRightContainer > div p {
  font-size: 18px;
}
/* .SubCategory1{
  border: 1px solid teal;
  color: white;
  display: none;
  position: absolute;

}
.SubCategory1>div{
  color: white;
} */
.MidScreenLogo {
  display: none;
}
.MidScreenLogo img {
  width: 25%;
}

/* @media only screen and (max-width: 767px) {
  .MenuContainer {
    width: 100%;
    height: 72px;
    position: fixed;
    left: 0;
    top: 0;
    z-index: 42;
    background-color: #fff;
    border-bottom: 1px solid #fff;
    display: flex;
    border: 1px solid rebeccapurple;
    align-items: center;
    flex-direction: row-reverse;
  }
  .MidScreenLogo {
    display: block;
    margin-left: 2.6rem;
    align-items: center;
    width: 100%;

  }

  .Menubox {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    position: absolute;
    align-items: center;
    width: 100%;
    height: 100%;
    transition: 0.3s ease-in-out;
    cursor: pointer;
    border: 1px solid greenyellow;
    margin-top: -7px;
  }
} */

@media only screen and (max-width: 767px) {
  .MenuContainer {
    display: none;
  }
}
